/** dev.platform.vysioneer.com */

import getHotkeys from './getHotkeys';
import { getPrefetcher, getDICOMwebUrl } from './getUrlSearchParams';

export default function config() {
  const baseMap = {
    default: 'https://vysioneer-prototype-platform.de.r.appspot.com/healthcare',
  };
  const urlRoot = getDICOMwebUrl({ baseMap: baseMap });
  return {
    routerBasename: '/',
    homepageUrl: 'https://dev.platform.vysioneer.com',
    redirectUrl: 'https://dev.platform.vysioneer.com',
    showPatientList: false,
    readOnly: false,
    enableOnPremiseAPI: false,
    enableSubmitRtstructButton: true,
    enableDownloadRtstructButton: false,
    enableRTExtension: true,
    enableTrackingExtension: false,
    enableLongitudinalExtension: true,
    enableLocal: true,
    firebaseConfig: {
      apiKey: 'AIzaSyCue_YQyjXzd1OjfKMeEvUFXJQxYBqCt8I',
      authDomain: 'prototype.vysioneer.com',
      projectId: 'vysioneer-prototype-platform',
      storageBucket: 'vysioneer-prototype-platform.appspot.com',
      messagingSenderId: '471576794235',
      appId: '1:471576794235:web:26a7dd0b5a10d551f7fede',
      measurementId: 'G-HX8GRW58VY',
      autoLogout: true,
      enableSubmitLogs: false,
      enableUpdateEndpoints: false,
    },
    dicomWebServer: 'healthcareApi',
    dicomWebProxy: {
      projectId: 'arched-jigsaw-248702',
      endpoint: baseMap,
    },
    service: {
      endpoint: {
        default:
          'https://vysioneer-prototype-platform.de.r.appspot.com/service',
      },
    },
    servers: {
      dicomWeb: [
        {
          name: 'Google Healthcare API',
          imageRendering: 'wadors',
          thumbnailRendering: 'wadors',
          type: 'dicomWeb',
          active: true,
          wadoUriRoot: urlRoot,
          qidoRoot: urlRoot,
          wadoRoot: urlRoot,
          supportsFuzzyMatching: false,
          qidoSupportsIncludeField: false,
        },
      ],
    },
    studyPrefetcher: getPrefetcher({
      enabled: true,
      displaySetCount: 3,
      maxNumPrefetchRequests: 30,
    }),
    maxConcurrentMetadataRequests: 5,
    maxCacheSizeInBytes: 2 * 1024 * 1024 * 1024,
    hotkeys: getHotkeys(),
  };
}
